<template>
  <div class="history">
    <section class="mt-20 mb-20 overflow-hidden">
      <div class="container-small px-5 xl:px-0">
        <h1 class="font-serif font-black text-3xl md:text-5xl uppercase mb-10 mt-0">
          {{ $t('history.title') }} <span class="text-red-700">{{ $t('history.title2') }}</span>
        </h1>

        <div class="my-5 prone" v-html="$t('history.history')"></div>


        <swiper
            class="winnerSwiper"
            :slides-per-view="'auto'"
            :space-between="20"
            :grabCursor="true">
          <swiper-slide v-for="(info, index) in winner.slice().reverse()" :key="`winner-${index}`">
            <div class="bg-slate-800 text-white rounded-lg shadow-xl p-5 overflow-hidden relative group">
              <div class="absolute left-0 right-0 top-0 bottom-0">
                <img :src="require(`@/assets/images/history/${info.img}`)" loading="lazy" alt="" class="object-cover object-right md:group-hover:scale-105 transition-all duration-1000">
              </div>
              <div class="relative">
                <div class="text-2xl font-black font-serif pointer-events-none" v-html="info.year"></div>
                <div class="font-serif text-xl pointer-events-none">
                  <span v-if="info.team" v-html="info.team"></span><span v-if="info.lang" v-html="$t(info.lang)"></span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>

      </div>
    </section>

    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <div class="relative grid grid-cols-1 md:grid-cols-3 bg-black h-full">
          <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none hidden md:block">
            <img src="@/assets/images/home/mbjc-magazin.jpeg" alt="tickets" class="object-cover w-full h-full">
          </div>
          <div class="md:hidden overflow-hidden relative pt-[85%] md:pt-[60%]">
            <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
              <img src="@/assets/images/home/mbjc-magazin.jpeg" alt="tickets" class="object-cover object-right w-full h-full">
            </div>
          </div>
          <div class="swiper-textbox col-span-3 md:col-span-1 ">
            <div class="text-center mb-5 font-serif font-black uppercase text-2xl" v-html="$t('magazine.title')"></div>
            <div class="prone" v-html="$t('magazine.text')"></div>
            <div class="mt-5 text-center">
              <a :href="$t('magazine.url')" target="_blank" v-html="$t('magazine.download')" class="button white"></a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-20 mb-20 relative">
      <div class="container-small px-5 xl:px-0">
        <div class="font-serif font-black text-3xl md:text-5xl uppercase mb-5">
          <span class="text-red-700">{{ $t('home.impressions') }}</span>
        </div>
      </div>
      <div class="sticky sticky-element">
        <div class="sticky-element__content bg-white py-5">
          <div class="container-small px-5 xl:px-0">
            <div class="swiper-nav">
              <div v-for="(nav, key) in gallery.categories"
                   :key="`impressionNav-${key}`"
                   v-html="nav"
                   :class="{ 'active': gallery.active === key }"
                   @click="gallery.active = key"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-small">
        <swiper
            v-if="gallery.active === 'honor'"
            class="impressionSwiper mb-10"
            :slides-per-view="'auto'"
            :space-between="10"
            :loop="true"
            :grabCursor="true">
          <swiper-slide v-for="(entry, index) in gallery.honor" :key="`honor-${index}`">
            <div class="relative group impressionContent">
              <img :src="require(`@/assets/images/gallery2023/honor/${entry.image}`)" loading="lazy" alt="" class="impressionImage">
              <div class="stars__text">
                <div class="relative text-center uppercase">
                  <div class="md:leading-none text-sm md:text-base origin-top md:opacity-0 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500" v-html="entry.text"></div>
                  <div class="text-xl md:text-3xl font-bold origin-top leading-none md:opacity-0 md:scale-y-150 group-hover:md:scale-y-100 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500 group-hover:md:delay-300" v-html="entry.title"></div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <swiper
            v-if="gallery.active === 'impression'"
            class="impressionSwiper mb-10"
            :slides-per-view="'auto'"
            :space-between="20"
            :loop="true"
            :grabCursor="true">
          <swiper-slide v-for="(entry, index) in gallery.impression" :key="`impression-${index}`">
            <div class="impressionContent">
              <img :src="require(`@/assets/images/${entry.image}`)" alt="" class="impressionImage">
            </div>
          </swiper-slide>
        </swiper>
        <swiper
            v-if="gallery.active === 'honor2024'"
            class="impressionSwiper mb-10"
            :slides-per-view="'auto'"
            :space-between="20"
            :loop="true"
            :grabCursor="true">
          <swiper-slide v-for="(entry, index) in gallery.honor2024" :key="`honor2024-${index}`">
            <div class="relative group impressionContent">
              <img :src="require(`@/assets/images/${entry.image}`)" alt="" class="impressionImage">
              <div class="stars__text">
                <div class="relative text-center">
                  <div class="md:leading-none text-sm md:text-base origin-top md:opacity-0 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500 mb-2" v-html="entry.text"></div>
                  <div class="uppercase text-xl md:text-3xl font-bold origin-top leading-none md:opacity-0 md:scale-y-150 group-hover:md:scale-y-100 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500 group-hover:md:delay-300" v-html="entry.title"></div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="pt-10 pb-20 bg-slate-200 text-slate-600">
      <div class="container-small px-5 xl:px-0 prone">
        <h2 v-html="$t('history.amateurTitle')"></h2>

        <div class="grid grid-cols-5 mt-10">
          <div class="col-span-5 md:col-span-3">
            <div class="prone mb-10" v-html="$t('history.amateurText')"></div>
          </div>
        </div>
        <swiper
            class="impressionSwiper mb-10"
            :slides-per-view="'auto'"
            :space-between="20"
            :loop="false"
            :grabCursor="true">
          <swiper-slide v-for="(entry, index) in gallery.youth" :key="`youth-${index}`">
            <div class="relative group impressionContent">
              <img :src="require(`@/assets/images/${entry.image}`)" loading="lazy" alt="" class="impressionImage">
              <div class="stars__text">
                <div class="relative text-center uppercase">
                  <div class="md:leading-none text-sm md:text-base origin-top md:opacity-0 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500" v-html="entry.text"></div>
                  <div class="text-xl md:text-3xl font-bold origin-top leading-none md:opacity-0 md:scale-y-150 group-hover:md:scale-y-100 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500 group-hover:md:delay-300" v-html="entry.title"></div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
import anime from 'animejs';
import Axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import { EffectCreative } from "swiper";
// Import Swiper styles

export default {
  name: 'HistoryComponent',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      winnerTrainee: 'GI1_8556.jpg',
      winner: [
        { year: 1991, team: 'RSC Anderlecht', img: 'winner_rsc.webp' },
        { year: 1992, team: 'Paris Saint-Germain', img: 'winner_psg.webp' },
        { year: 1993, team: 'Eintracht Frankfurt', img: 'winner_frankfurt99.webp' },
        { year: 1994, lang: 'team.hungary', img: 'winner_hungary.webp' },
        { year: 1995, team: 'VfB Stuttgart', img: 'winner_vfb.webp' },
        { year: 1996, team: 'VfB Stuttgart', img: 'winner_vfb.webp' },
        { year: 1997, lang: 'team.turkey', img: 'winner_turkey.webp' },
        { year: 1998, team: 'Berlin', img: 'winner_berlin.webp' },
        { year: 1999, team: 'Steiermark', img: 'winner_steiermark.webp' },
        { year: 2000, team: 'VfB Stuttgart / ', lang: 'team.austria', img: 'winner_vfb_austria.webp' },
        { year: 2001, team: 'Borussia Dortmund', img: 'winner_bvb.webp' },
        { year: 2002, team: 'VfB Stuttgart', img: 'winner_vfb.webp' },
        { year: 2003, team: 'Hertha BSC', img: 'winner_bsc.webp' },
        { year: 2004, team: 'Hertha BSC', img: 'winner_bsc.webp' },
        { year: 2005, team: 'Werder Bremen', img: 'winner_werder.webp' },
        { year: 2006, team: 'FC Schalke 04', img: 'winner_schalke.webp' },
        { year: 2007, team: 'VfB Stuttgart', img: 'winner_vfb.webp' },
        { year: 2008, team: 'Dinamo Zagreb', img: 'winner_dinamo.webp' },
        { year: 2009, team: 'Karlsruher SC', img: 'winner_ksc.webp' },
        { year: 2010, team: 'Bayer 04 Leverkusen', img: 'winner_bayer.webp' },
        { year: 2011, team: 'SC Freiburg', img: 'winner_scf.webp' },
        { year: 2012, team: '1. FSV Mainz 05', img: 'winner_mainz.webp' },
        { year: 2013, team: 'FC Schalke 04', img: 'winner_schalke.webp' },
        { year: 2014, team: 'FC Schalke 04', img: 'winner_schalke.webp' },
        { year: 2015, team: 'Manchester United', img: 'winner_manu.webp' },
        { year: 2016, team: 'SK Rapid Wien', img: 'winner_rapid.webp' },
        { year: 2017, team: 'TSG 1899 Hoffenheim', img: 'winner_hoffenheim.webp' },
        { year: 2018, team: 'Hertha BSC', img: 'winner_bsc.webp' },
        { year: 2019, team: 'FC Liverpool', img: 'winner_liverpool.webp' },
        { year: 2020, team: 'SK Rapid Wien', img: 'winner_rapid.webp' },
        { year: 2023, team: 'SK Rapid Wien', img: 'winner_rapid.webp' },
        { year: 2024, team: 'VfB Stuttgart', img: 'winner_vfb.webp' },
        { year: 2025, team: 'VfB Stuttgart', img: 'winner_vfb.webp' },
      ],
      gallery: {
        active: 'impression',
        categories: { 'impression': this.$t('gallery.impression'), 'honor2024': `${this.$t('gallery.honor')} 2024`, 'honor': `${this.$t('gallery.honor')} 2023` },
        honor: [
          { image: 'mvp_pros.webp', title: 'MVP', text: 'Nicolas Bajlicz (Rapid Wien)' },
          { image: 'fair_play_award.webp', title: 'Fair Play Award', text: 'Werk Berlin / Ludwigsfelde' },
          { image: 'best_trainee.webp', title: 'Best Trainee', text: 'Justin Bretgeld (Werk Bremen)' },
          { image: 'best_goalscorer.webp', title: 'Best Goalscorer', text: 'Nicolas Bajlicz (Rapid Wien)' },
          { image: 'best_goalkeeper.webp', title: 'Best Goalkeeper', text: 'Silas Prüfrock (RB Leipzig)' },
          { image: 'winner_pros.webp', title: 'Winner', text: 'Rapid Wien' },
          { image: 'winner_trainees.webp', title: 'Winner Trainees', text: 'Werk Bremen' },
        ],
        honor2024: [
          { image: 'gallery2024/winner/01_mvp.webp', text: 'MVP', title: 'Baris Kalayci<br/>1. FC Union Berlin' },
          { image: 'gallery2024/winner/02_ejc.webp', text: 'WINNER eJC<br/>SK Gaming eJuniorCup 2024 powered by Mercedes-Benz', title: 'Robert Brysik' },
          { image: 'gallery2024/winner/03_fairplay.webp', text: 'FAIR PLAY AWARD', title: 'Mercedes-Benz Werk Hamburg' },
          { image: 'gallery2024/winner/04_trainee.webp', text: 'BEST TRAINEE', title: 'Justin Bretgeld<br/>Mercedes-Benz Werk Bremen' },
          { image: 'gallery2024/winner/05_scorer.webp', text: 'BEST GOAL SCORER', title: 'Daris Djezic<br/>SK Rapid' },
          { image: 'gallery2024/winner/06_keeper.webp', text: 'BEST GOAL KEEPER', title: 'Timo Ulpins<br/>VfB Stuttgart' },
        ],
        impression: [
          // { image: 'gallery2024/impression/1.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/2.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/3.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/4.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/5.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/6.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/7.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/8.webp', title: '', text: '' },
          // { image: 'gallery2024/impression/9.webp', title: '', text: '' },
          { image: 'gallery2023/impression/1.webp', title: '', text: '' },
          { image: 'history/GI1_5326.jpg', title: '', text: '' },
          { image: 'gallery2023/impression/9.webp', title: '', text: '' },
          { image: 'gallery2023/impression/2.webp', title: '', text: '' },
          { image: 'history/MG2_9247.jpg', title: '', text: '' },
          { image: 'history/MG1_1015.jpg', title: '', text: '' },
          { image: 'history/GI2_5261.jpg', title: '', text: '' }
        ],
        youth: [
          { image: 'history/amateur-2024.webp', title: 'Werk Bremen', text: '2024' },
          { image: 'history/amateur-2023.webp', title: 'Werk Bremen', text: '2023' },
        ]
      }
    }
  },
  methods: {},
  mounted() {
    //
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
.history {
  h2 {
    @apply font-serif font-black text-xl md:text-2xl uppercase mb-5 mt-10 text-red-700;
  }
  h3 {
    @apply font-bold text-xl uppercase mb-5 mt-0;
  }
  h4 {
    @apply font-bold;
  }
}
.swiper.impressionSwiper {
  overflow: visible;
}
.impressionContent {
  aspect-ratio: 4 / 3;
  width: 800px;
  max-width: 85vw;
}
.swiper.winnerSwiper {
  overflow: visible;
  .swiper-slide {
    width: 350px;
    max-width: 80vw;
  }
}
</style>
