<template>
  <div class="livestream" :class="{ 'view': visible && active }">
    <div class="relative">
      <div class="text-sm text-white px-3 py-1">LIVESTREAM</div>
      <div class="absolute right-0 top-0 bottom-0 flex items-center text-white bg-slate-600 px-3 cursor-pointer hover:bg-white hover:text-slate-800 transition-all" @click="visible = false">
        <div>X</div>
      </div>
    </div>
    <div class="aspect-video rounded-b" v-if="visible && active">
      <iframe class="rounded-b" width="100%" height="100%" :src="url" frameborder="0" allow="webkitAllowFullScreen; mozallowfullscreen; allowfullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import Axios from 'axios';
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { EffectCreative } from "swiper";
// Import Swiper styles

export default {
  name: 'LivestreamComponent',
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
    active: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {},
  mounted() {
    //
    // const script = document.createElement('script');
    // script.src = 'https://player.cloud.wowza.com/hosted/2q8xqj7y/wowza.js';
    // script.type = 'text/javascript';
    // script.id = 'player_embed';
    // script.crossOrigin = 'anonymous';
    // this.$nextTick(() => {
    //   document.body.appendChild(script);
    // });
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
//
.livestream {
  @apply fixed md:bottom-10 md:right-10 bottom-5 right-5 shadow-lg w-80 border-2 border-white hover:w-96 transition-all bg-slate-800 rounded transition-all scale-50 opacity-0;
  max-width: calc(100vw - 40px);
  z-index: 800;
  &.view {
    @apply pointer-events-auto scale-100 opacity-100;
  }
}
</style>
