<template>
  <div class="home">
    <h1 class="hidden">Mercedes-Benz JuniorCup 2025</h1>
    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <div class="prone text-neutral-500 mb-5" v-html="$t('juniorCup.history')"></div>
        <div class="text-center text-sm">
          <router-link :to="{ name: 'history', params: { locale: $i18n.locale } }" class="button">{{ $t('more') }}</router-link>
        </div>
      </div>
    </section>

    <section class="mt-20 mb-20 bg-slate-100 pt-10 pb-20" v-if="news.length">
      <div class="container-small">
        <div class="font-serif font-black text-3xl md:text-5xl uppercase mb-10 px-5 xl:px-0">
          {{ $t('home.news') }}
        </div>
        <swiper
            class="starsSwiper"
            v-scroll:in="() => newsVisible = true"
            :slides-per-view="'auto'"
            :breakpoints="{ 768: { slidesPerView: 3, spaceBetween: 20 } }"
            :space-between="10"
            :grabCursor="true">
          <template v-for="(entry, index) in news" :key="`news-${entry.key}`">
            <swiper-slide v-if="entry.title || entry.text">
              <div class="relative fade-in group box bg-white shadow-xl border-t-4 border-t-red-700" style="max-width: 70vw" :class="[ newsVisible ? 'view' : '', `delay-${index * 100 + 200}` ]">
                <div class="bg-slate-300 text-white relative" v-if="entry.image">
                  <img :src="entry.image" :alt="entry.title" class="w-full" loading="lazy">
                  <div class="text-xs mb-1 text-right text-white drop-shadow absolute bottom-3 right-5" v-html="getDate(entry.date)"></div>
                </div>
                <div class="pt-3 text-xs mb-1 text-right text-slate-500 px-5" v-if="!entry.image" v-html="getDate(entry.date)"></div>
                <div class="p-5">
                  <div class="font-black text-red-700 text-xl" v-html="entry.title"></div>
                  <div class="text-sm mt-5 prone text-slate-800" v-html="entry.text"></div>
                  <div class="pt-5" v-if="entry.link"><a :href="entry.link" class="text-red-700 text-sm uppercase font-black underline underline-offset-4 hover:text-black">{{ entry.linkTitle || 'Link' }}</a></div>
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
    </section>

    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <div class="relative grid grid-cols-1 md:grid-cols-3 bg-black h-full">
          <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none hidden md:block">
            <img src="@/assets/images/home/mbjc-magazin.jpeg" alt="tickets" class="object-cover w-full h-full">
          </div>
          <div class="md:hidden overflow-hidden relative pt-[85%] md:pt-[60%]">
            <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
              <img src="@/assets/images/home/mbjc-magazin.jpeg" alt="tickets" class="object-cover object-right w-full h-full">
            </div>
          </div>
          <div class="swiper-textbox col-span-3 md:col-span-1 ">
            <div class="text-center mb-5 font-serif font-black uppercase text-2xl" v-html="$t('magazine.title')"></div>
            <div class="prone" v-html="$t('magazine.text')"></div>
            <div class="mt-5 text-center">
              <a :href="$t('magazine.url')" target="_blank" v-html="$t('magazine.download')" class="button white"></a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-20 mb-20" v-if="ticketsVisible">
      <div class="container-small px-5 xl:px-0">
        <div class="font-serif font-black text-3xl md:text-5xl uppercase mb-5">
          <span class="text-red-700">{{ $t('tickets.tickets') }}</span> & {{ $t('tickets.travel') }}
        </div>
      </div>
      <div class="relative my-5">
        <div class="sticky sticky-element">
          <div class="sticky-element__content bg-white py-5">
            <div class="container-small px-5 xl:px-0">
              <div class="swiper-nav">
                <div v-for="nav in ticketSwiper.nav"
                     :key="`ticketSwiper-${nav.target}`"
                     v-html="$t(nav.title)"
                     :class="{ 'active': ticketSwiper.active === nav.target }"
                     @click="swiperGoto('ticketSwiper', nav.target)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-small px-5 xl:px-0">
          <swiper
              id="ticketSwiper"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :slides-per-view="1"
              :space-between="0"
              :grabCursor="true"
              :effect="'creative'"
              :modules="modules"
              :creativeEffect="{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }"
          >
            <swiper-slide>
              <div class="relative grid grid-cols-1 md:grid-cols-3 bg-black h-full">
                <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none hidden md:block">
                  <img src="@/assets/images/home/travel-map.jpg" alt="tickets" class="object-cover w-full h-full">
                </div>
                <div class="md:hidden overflow-hidden relative" style="padding-top: 60%">
                  <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
                    <img src="@/assets/images/home/travel-map.jpg" alt="tickets" class="object-cover w-full h-full">
                  </div>
                </div>
                <div class="swiper-textbox col-span-3 md:col-span-1 md:col-start-3">
                  <div class="text-center mb-5 font-serif font-black uppercase text-2xl" v-html="$t('tickets.travel')"></div>
                  <div class="prone" v-html="$t('tickets.travelShortDescription')"></div>
                  <div class="prone mt-5 font-bold" v-html="$t('tickets.travelLocation')"></div>
                  <div class="mt-5 flex gap-5 flex-col md:flex-row">
                    <div>
                      <router-link :to="{ name: 'travel', params: { locale: $i18n.locale } }" class="button white">{{ $t('more') }}</router-link>
                    </div>
                    <div>
                      <a :href="$t('tickets.travelLink')" target="_blank" v-html="$t('tickets.travelLinkTitle')" class="button white"></a>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="relative grid grid-cols-1 md:grid-cols-3 bg-black h-full">
                <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none hidden md:block">
                  <img src="@/assets/images/home/tickets.webp" alt="tickets" class="object-cover w-full h-full">
                </div>
                <div class="md:hidden overflow-hidden relative" style="padding-top: 60%">
                  <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
                    <img src="@/assets/images/home/tickets-mobile.webp" alt="tickets" class="object-cover w-full h-full">
                  </div>
                </div>
                <div class="swiper-textbox col-span-3 md:col-span-1 ">
                  <div class="text-center mb-5 font-serif font-black uppercase text-2xl" v-html="$t('tickets.tickets')"></div>
                  <div class="prone" v-html="$t('tickets.ticketsText')"></div>
                  <div class="mt-5 text-center">
                    <a :href="settings && settings.ticketUri ? settings.ticketUri : $t('tickets.ticketsLink')" target="_blank" v-html="$t('tickets.ticketsLinkTitle')" class="button white"></a>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section class="bg-black text-white pt-10 pb-20 relative">
      <div class="absolute left-0 right-0 top-0 bottom-0">
        <img src="@/assets/images/teams/team-bg.jpg" class="object-cover w-full h-full">
        <div class="absolute left-0 right-0 bottom-0 top-0 backdrop-blur-sm bg-black/10"></div>
      </div>
      <div class="container-small relative px-5 xl:px-0" v-scroll:in="() => teamsVisible = true">
        <div class="font-serif font-black text-3xl md:text-5xl uppercase mb-10">{{ $t('team.title') }}</div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-5 md:mx-20">
          <template  v-for="(team, index) in teams" :key="`team-${team}`">
            <div class="bg-neutral-800 py-10 px-5 text-center flex justify-between flex-col fade-in"
                 v-if="team.tournament === 'profi' && !team.placeholder"
                 :class="[ teamsVisible ? 'view' : '', `delay-${index * 100 + 200}` ]">
              <div>
                <img :src="team.logo" v-if="team.logo" class="team__logo">
              </div>
              <div class="mt-10 font-bold">{{ team.name }}</div>
            </div>
          </template>
        </div>
      </div>
    </section>

    <section class="pt-10 pb-20 bg-slate-200 text-slate-600 hidden">
      <div class="container-small px-5 xl:px-0 prone">
        <div class="font-serif font-black text-3xl md:text-5xl uppercase mb-10 px-5 xl:px-0">
          <h2 v-html="$t('home.thankyou')"></h2>
        </div>

        <img :src="require('@/assets/images/home/thankyou.webp')" loading="lazy" :alt="$t('home.thankyou')">
      </div>
    </section>

    <section class="mt-20 mb-20">
      <div class="container-small">
        <div class="font-serif font-black text-3xl md:text-5xl uppercase mb-10 px-5 xl:px-0">
          {{ $t('stars.title') }} <span class="text-red-700">MBJC</span>
        </div>
        <swiper
            class="starsSwiper"
            v-scroll:in="() => starsVisible = true"
            :slides-per-view="'auto'"
            :breakpoints="{ 768: { slidesPerView: 3, spaceBetween: 20 } }"
            :space-between="10"
            :grabCursor="true">
          <swiper-slide v-for="(star, index) in stars" :key="`star-${star.last}`">
            <div class="relative bg-black h-[500px] md:h-[600px] fade-in group" :class="[ starsVisible ? 'view' : '', `delay-${index * 100 + 200}` ]">
              <div class="h-[500px] md:h-[600px] overflow-clip">
                <img :src="require(`@/assets/images/stars/${star.image}`)" loading="lazy" :alt="`${star.first } ${ star.last }`" class="object-cover w-full h-full md:hover:scale-110 transition-all duration-1000">
              </div>
              <div class="stars__text">
                <div class="relative text-center uppercase">
                  <div class="leading-none md:opacity-0 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500" v-html="star.first"></div>
                  <div class="text-3xl font-bold leading-none md:opacity-0 md:translate-y-5 group-hover:opacity-100 group-hover:md:translate-y-0 transition-all duration-500 group-hover:md:delay-300" v-html="star.last"></div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
import anime from 'animejs';
import axios from 'axios';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCreative } from "swiper";
// Import Swiper styles

export default {
  name: 'HomeComponent',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      interval: null,
      modules: [EffectCreative],
      ticketsVisible: true,
      ticketSwiper: {
        el: null,
        nav: [
            { title: 'tickets.travel', target: 0 },
            { title: 'tickets.tickets', target: 1 },
        ],
        active: 0
      },
      teams: [],
      news: [],
      teamsVisible: true,
      stars: [
        { first: 'Joshua', last: 'Kimmich', image: 'kimmich.webp', text: '' },
        { first: 'Marcus', last: 'Rashford', image: 'rashford.webp', text: '' },
        { first: 'Leroy', last: 'Sané', image: 'sane.webp', text: '' },
        { first: 'Nuri', last: 'Sahin', image: 'sahin.webp', text: '' },
        { first: 'Benedikt', last: 'Höwedes', image: 'howedes.webp', text: '' },
      ],
      settings: {},
      starsVisible: false,
      newsVisible: false
    }
  },
  computed: {
    imageHeight() {
      return window.innerWidth < 1170 ? window.innerWidth * .9 * .666 : 1170 * .666;
    }
  },
  methods: {
    getDate: function (str) {
      const d = new Date(str);
      const day = (`00${d.getDate()}`).slice(-2);
      const month = (`00${d.getMonth() + 1}`).slice(-2);
      return `${day}.${month}.${d.getFullYear()}`;
    },
    swiperGoto: function (swiper, slide) {
      if (this[swiper]) {
        this[swiper].el.slideTo(slide, 600);
      }
    },
    onSwiper: function (swiper) {
      const id = swiper.$el.attr('id');
      if (this[id]) {
        this[id].el = swiper;
        this[id].active = swiper.activeIndex;
      }
    },
    onSlideChange: function (swiper) {
      const id = swiper.$el.attr('id');
      if (this[id]) {
        this[id].active = swiper.activeIndex;
      }
    },
    fetch: async function () {
      const now = new Date();
      const timestamp = `${now.getFullYear()}${(`00${now.getMonth() + 1}`).slice(-2)}${(`00${now.getDate()}`).slice(-2)}${(`00${now.getHours()}`).slice(-2)}${(`00${now.getMinutes()}`).slice(-2)}`;
      // direct api call: https://cmsdata.net/mbjc-api/data/${this.$i18n.locale}
      // json file: https://cmsdata.net/static/mbjc_${this.$i18n.locale}.json?v=${now.now()/1000}
      await axios.get(`https://cmsdata.net/mbjc-api/data/${this.$i18n.locale}`, {  }).then((res) => {
        if (res.data && res.data.teams) {
          this.news = res.data.news;

          const teams = {};
          const tbd = {};
          Object.keys(res.data.teams).forEach(teamKey => {
            if (res.data.teams[teamKey].name === 'TBD') {
              tbd[teamKey] = res.data.teams[teamKey];
            } else {
              teams[teamKey] = res.data.teams[teamKey];
            }
          });
          this.teams = {...teams, ...tbd};
          this.loaded = true;
        }
        if (res.data && res.data.settings) {
          this.settings = res.data.settings;
        }
      });
    }
  },
  mounted() {
    this.fetch();
    this.interval = setInterval(() => {
      this.fetch();
    }, 60 * 10000);
    this.observer = new IntersectionObserver(
        ([e]) => {
          // console.log(e.intersectionRatio);
          e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
        },
        {
          threshold: [1],
          rootMargin: '-65px 0px 0px 0px'
        }
    );
    this.observerMobile = new IntersectionObserver(
        ([e]) => {
          // console.log(e.intersectionRatio);
          e.target.classList.toggle('is-pinned-mobile', e.intersectionRatio < 1)
        },
        {
          threshold: [1],
          rootMargin: '-91px 0px 0px 0px'
        }
    );
    this.$nextTick(() => {
      const elements = document.getElementsByClassName('sticky');
      [...elements].forEach((el) => this.observer.observe(el));
      [...elements].forEach((el) => this.observerMobile.observe(el));
    });
  },
  unmounted() {
    clearInterval(this.interval);
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style lang="scss">
.impressionSwiper {
  .swiper-slide {
    max-width: 90vw;
    width: auto;
  }
}
.impressionImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home {
  max-width: 100vw;
  overflow-x: hidden;
}
.stars__text {
  @apply relative md:absolute md:bottom-0 md:left-0 md:right-0 md:text-white px-5 pb-5 pt-5 md:pt-20 md:bg-gradient-to-b md:from-slate-800/0 md:to-slate-800;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.swiper.starsSwiper {
  overflow: visible;
  .swiper-slide {
    width: 80vw;
  }
}
</style>
