<template>
  <div class="tickets">
    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <h1 class="font-serif font-black text-3xl md:text-5xl uppercase mb-10 mt-0">
          <span class="text-red-700">{{ $t('tickets.tickets') }}</span> & {{ $t('tickets.travel') }}
        </h1>
      </div>
      <div class="bg-slate-100 my-20 py-20 hidden">
        <div class="container-small px-5 xl:px-0">
          <div class="bg-white p-5 prose prone shadow-xl border-t-4 border-t-red-700">
            <p class="font-bold">
              <span v-if="$i18n.locale === 'de'">Für die Anreise am Sonntag, 12.01.2025 möchten wir folgende Hinweise geben:</span>
              <span v-if="$i18n.locale === 'en'">We would like to provide the following information for your arrival on Sunday, 12.01.2025:</span>
            </p>
            <ul class="pl-5 list-disc pb-5">
              <li>
                <span v-if="$i18n.locale === 'de'">Nutzt die Parkhäuser in der Innenstadt oder fahrt mit der S-Bahn bis zur Station Sindelfingen. Ab dem ZOB Sindelfingen fährt immer zur Minute 06 (Achtung Stundentakt) die Buslinie 705 in Richtung Maichingen Landhaussiedlung bis zur Haltestelle Glaspalast.</span>
                <span v-if="$i18n.locale === 'en'">Use the parking garages in the city center or take the S-Bahn to Sindelfingen station. From the Sindelfingen central bus station, bus no. 705 in the direction of Maichingen Landhaussiedlung leaves at minute 06 (every hour) and stops at Glaspalast.</span>
              </li>
              <li>
                <span v-if="$i18n.locale === 'de'">Zusätzlich werden zwischen dem Parkplatz Gymnasium Maichingen sowie dem Bahnhof/ZOB Sindelfingen und dem Glaspalast regelmäßig Shuttlebusse verkehren.</span>
                <span v-if="$i18n.locale === 'en'">In addition, shuttle buses will run regularly between the Maichingen Gymnasium parking lot and the Sindelfingen train station/train station and the Glaspalast.</span>
              </li>
            </ul>
            <p>
              <span v-if="$i18n.locale === 'de'">Für die Rückreise fahren in Richtung Parkplatz Gymnasium und ZOB Sindelfingen regelmäßig Shuttlebusse sowie die Linie 705 zur Minute 52 (Fahrtrichtung Maichingen) und Minute 34 (Fahrtrichtung Sindelfingen).</span>
              <span v-if="$i18n.locale === 'en'">For the return journey, there are regular shuttle buses in the direction of the Gymnasium parking lot and the Sindelfingen central bus station, as well as line 705 at minute 52 (direction Maichingen) and minute 34 (direction Sindelfingen).</span>
            </p>

          </div>
        </div>
      </div>
      <div class="container-small px-5 xl:px-0">
        <div class="relative grid grid-cols-1 md:grid-cols-3 bg-black mb-10">
          <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none hidden md:block">
            <img src="@/assets/images/home/tickets.webp" alt="tickets" class="object-cover w-full h-full">
          </div>
          <div class="md:hidden overflow-hidden relative" style="padding-top: 100%">
            <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
              <img src="@/assets/images/home/tickets-mobile.webp" alt="tickets" class="object-cover w-full h-full">
            </div>
          </div>
          <div class="swiper-textbox md:col-span-1 ">
            <div class="text-center mb-5 font-serif font-black uppercase text-2xl" v-html="$t('tickets.tickets')"></div>
            <div class="prone" v-html="$t('tickets.ticketsText')"></div>
            <div class="mt-5 text-center">
              <a :href="settings && settings.ticketUri ? settings.ticketUri : $t('tickets.ticketsLink')" target="_blank" v-html="$t('tickets.ticketsLinkTitle')" class="button white"></a>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="mt-10 mb-20 relative">
      <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
        <img src="@/assets/images/bg-pattern.jpg" alt="bg" class="object-cover w-full h-full">
        <div class="absolute left-0 right-0 top-0 bottom-0 bg-black/70"></div>
      </div>
      <div class="container-small px-5 xl:px-0 py-20">
        <div class="relative font-serif text-white text-2xl md:text-3xl uppercase mb-10 mt-0 font-bold" v-html="$t('tickets.ticketListTitle')"></div>
        <div class="relative grid gap-5 items-center grid-cols-1 md:grid-cols-4">
          <div class="col-span-4 md:col-span-2 lg:col-span-3 pb-10 md:pb-0">
            <img src="@/assets/images/travel/tickets.svg" alt="Tickets" class="pointer-events-none w-full">
            <p class="mt-5 text-white">
              * SK Gaming eJuniorCup 2025 – Powered by Mercedes-Benz
            </p>
          </div>
          <div class="col-span-4 md:col-span-2 lg:col-span-1">
            <div class="grid gap-2 grid-cols-1">
              <div class="p-3 text-white bg-[#1990BA] hover:bg-gray-900 hover:text-[#1990BA] transition-all">
                <div class="flex gap-5 items-center divide-x divide-white">
                  <div class="flex-1">
                    <div class="text-4xl font-serif font-bold">1</div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketCategory')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    25 €
                  </div>
                </div>
              </div>

              <div class="p-3 text-white bg-[#1990BA] hover:bg-gray-900 hover:text-[#1990BA] transition-all">
                <div class="flex gap-5 items-center divide-x divide-white">
                  <div class="flex-1">
                    <div class="text-4xl font-serif font-bold">1</div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketReduced')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    20 €
                  </div>
                </div>
              </div>

              <div class="p-3 text-white bg-[#F1D539] hover:bg-gray-900 hover:text-[#F1D539] transition-all">
                <div class="flex gap-5 items-center divide-x divide-white">
                  <div class="flex-1">
                    <div class="text-4xl font-serif font-bold">2</div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketCategory')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    15 €
                  </div>
                </div>
              </div>

              <div class="p-3 text-white bg-[#F1D539] hover:bg-gray-900 hover:text-[#F1D539] transition-all">
                <div class="flex gap-5 items-center divide-x divide-white">
                  <div class="flex-1">
                    <div class="text-4xl font-serif font-bold">2</div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketReduced')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    10 €
                  </div>
                </div>
              </div>

              <div class="p-3 text-gray-600 bg-white hover:bg-gray-900 hover:text-white transition-all">
                <div class="flex gap-5 items-center divide-x divide-gray-600">
                  <div class="flex-1">
                    <div class="text-4xl font-serif font-bold">3</div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketStand')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    10 €
                  </div>
                </div>
              </div>

              <div class="p-3 text-gray-600 bg-white hover:bg-gray-900 hover:text-white transition-all">
                <div class="flex gap-5 items-center divide-x divide-gray-600">
                  <div class="flex-1">
                    <div class="text-4xl font-serif font-bold">3</div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketReduced')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    7 €
                  </div>
                </div>
              </div>

              <div class="p-3 text-white bg-[#DA3332] hover:bg-gray-900 hover:text-[#DA3332] transition-all">
                <div class="flex gap-5 items-center divide-x divide-white">
                  <div class="flex-1">
                    <div class="text-2xl uppercase font-serif font-bold " v-html="$t('tickets.ticketVIP')"></div>
                    <div class="uppercase text-sm" v-html="$t('tickets.ticketCategory')"></div>
                  </div>
                  <div class="text-right text-4xl font-serif font-bold flex-1">
                    119 €
                  </div>
                </div>
              </div>

              <div class="text-center pt-5">
                <a :href="settings && settings.ticketUri ? settings.ticketUri : $t('tickets.ticketsLink')" target="_blank" v-html="$t('tickets.ticketsLinkTitle')" class="button white"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-10 mb-20">
      <div class="container-small px-5 xl:px-0">
        <div class="relative grid grid-cols-1 md:grid-cols-3 bg-black mb-10">
          <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none hidden md:block">
            <img src="@/assets/images/home/travel-map.jpg" alt="tickets" class="object-cover w-full h-full">
          </div>
          <div class="md:hidden overflow-hidden relative" style="padding-top: 100%">
            <div class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none">
              <img src="@/assets/images/home/travel-map.jpg" alt="tickets" class="object-cover w-full h-full">
            </div>
          </div>
          <div class="swiper-textbox col-span-3 md:col-span-1 md:col-start-3">
            <div class="text-center mb-5 font-serif font-black uppercase text-2xl" v-html="$t('tickets.travel')"></div>
            <div class="prone" v-html="$t('tickets.travelDescription')"></div>
            <div class="prone mt-5 font-bold" v-html="$t('tickets.travelLocation')"></div>
            <div class="mt-5 flex gap-5 flex-col md:flex-row">
              <div>
                <a :href="$t('tickets.travelLink')" target="_blank" v-html="$t('tickets.travelLinkTitle')" class="button white"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import anime from 'animejs';
import Axios from 'axios';
import axios from "axios";
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { EffectCreative } from "swiper";
// Import Swiper styles

export default {
  name: 'TicketsComponent',
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      // modules: [EffectCreative],
      categories: [
        { number: 1, text: this.$t('tickets.category1text'), price: 25, classes: 'bg-red-700' }
      ],
      settings: {}
    }
  },
  methods: {
    fetch: async function () {
      const now = new Date();
      const timestamp = `${now.getFullYear()}${(`00${now.getMonth() + 1}`).slice(-2)}${(`00${now.getDate()}`).slice(-2)}${(`00${now.getHours()}`).slice(-2)}${(`00${now.getMinutes()}`).slice(-2)}`;
      // direct api call: https://cmsdata.net/mbjc-api/data/${this.$i18n.locale}
      // json file: https://cmsdata.net/static/mbjc_${this.$i18n.locale}.json?v=${now.now()/1000}
      await axios.get(`https://cmsdata.net/mbjc-api/data/${this.$i18n.locale}?v=${timestamp}`, {  }).then((res) => {
        if (res.data && res.data.settings) {
          this.settings = res.data.settings;
        }
      });
    }
  },
  mounted() {
    this.fetch();
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
//
</style>
