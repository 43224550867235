<template>
  <div class="history">
    <section class="mt-20 mb-5 overflow-hidden">
      <div class="container-small px-5 xl:px-0">
        <h1 class="font-serif font-black text-3xl md:text-5xl mb-10 mt-0">
          Mercedes-Benz<br/>
          <span class="text-red-700">JuniorCup App</span>
        </h1>

        <div class="my-5 prone md:mr-64" v-html="$t('app.description')" style="text-wrap: balance">
        </div>
      </div>
    </section>

    <section class="mt-5 mb-10 py-10 bg-slate-200 text-slate-800">
      <div class="container-small">
        <div class="flex gap-10 justify-start px-5 xl:px-0">
          <div>
            <a href="https://apps.apple.com/de/app/mercedes-benz-juniorcup/id6738960305" target="_blank">
              <img :src="require(`@/assets/images/appstore/IOS_App_Store_Badge_${$i18n.locale.toUpperCase()}.svg`)" alt="App Store" class="h-10 md:h-16">
            </a>
          </div>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.mercedes.juniorcup.android" target="_blank">
              <img :src="require(`@/assets/images/appstore/google-play-badge_${$i18n.locale.toUpperCase()}.svg`)" alt="Play Store" class="h-10 md:h-16">
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="relative">
      <div class="container-small">
        <swiper
            class="impressionSwiper mb-10"
            :slides-per-view="'auto'"
            :space-between="10"
            :grabCursor="true">
          <swiper-slide v-for="(entry, index) in gallery.impression" :key="`honor-${index}`">
            <div class="relative group">
              <img :src="require(`@/assets/images/${entry.image}`)" loading="lazy" alt="">
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>
  </div>
</template>

<script>
import anime from 'animejs';
import Axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import { EffectCreative } from "swiper";
// Import Swiper styles

export default {
  name: 'AppComponent',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      gallery: {
        active: 'impression',
        impression: [
          { image: 'appstore/01.png', title: '', text: '' },
          { image: 'appstore/02.png', title: '', text: '' },
          { image: 'appstore/03.png', title: '', text: '' },
          { image: 'appstore/04.png', title: '', text: '' },
          { image: 'appstore/05.png', title: '', text: '' },
        ],
      }
    }
  },
  methods: {},
  mounted() {
    //
  },
  unmounted() {
    //
  }
};
</script>
